body {
    font-family: 'Roboto', Arial, sans-serif;
    color: #000;
    box-sizing: border-box;
}

.visibility-hidden {
    visibility: hidden;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

section.page-title {
    margin-bottom: 20px;
}