.unit-converter {
    .box-table {
        display: flex;
        justify-content: space-between;

        input, select {
            width: 100%;
            overflow: scroll;
        }

        .left {
            width: 49%;
        }

        .right {
            width: 49%;
        }
    }
}