$shadow-color: #000;
$background-color: #dee2e6;
$btn-hover-color: #949494;

.casio-calculator {
  .container {
    padding: 20px;
    text-align: center;
    box-shadow: 0 0 15px $shadow-color;
    border-radius: 25px;
    background-color: $background-color;
    box-shadow: inset 4px 4px 2px #686666, inset -4px -4px 2px #666565;
    max-width: max-content;
  }

  .row-calc {
    display: flex;
    justify-content: space-between;
  }

  .box-text {
    color: aliceblue;
    margin: 5px;

    h1 {
      text-align: left;
      font-size: 23px;
      outline: $shadow-color;
    }

    small {
      float: right;
      font-size: 12px;
    }
  }

  .display-sc {
    input {
      width: 280px;
      max-width: 100%;
      height: 80px;
      border: $shadow-color;
      outline: green;
      padding: 10px;
      border-radius: 5px;
      margin: 10px;
      background-color: rgb(27, 85, 55);
      color: rgb(236, 229, 229);
      font-size: 24px;
      cursor: pointer;
      box-shadow: inset 8px 8px 3px $shadow-color, inset -8px -8px 3px $shadow-color;

      &::placeholder {
        color: azure;
      }
    }
  }

  button {
    width: 60px;
    height: 35px;
    margin: 3px;
    background-color: lightslategray;
    color: aliceblue;
    font-size: 17px;
    border-radius: 5px;
    box-shadow: 1px 1px 0px $shadow-color;
    line-height: 0;

    &:hover,
    &:active,
    &:focus-visible {
      background-color: $btn-hover-color;
      color: #fff;
    }
  }

  .support-btns {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    button {
      width: max-content;
      min-width: 40px;
      height: 40px;
      font-size: 14px;
      background: linear-gradient(145deg, #171717, #272627);
      border-radius: 50%;
      outline: none;
      border: 2.5px #090909 solid;
      box-shadow: inset 1px 1px 0px #7d7c7e;
      margin: 10px;
      padding: 5px;

      &:active {
        transform: translateX(4px);
      }
    }

    .bg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #171717;
      border-radius: 150px;
      margin: 15px;
      height: 32px;
      box-shadow: inset 2px 2px 2px $shadow-color, inset -2px -2px 2px;
    }
  }

  .funct-keys {

    button {
      width: 50px;
      height: 30px;
      font-size: 14px;
      background-color: rgb(37, 54, 54);
      margin-top: 5px;
    }
  }

  .basic-keys {
    margin: 5px;
  }

  .row {
    span {
      background-color: $shadow-color;
      height: 100px;
    }
  }

  #left-btn,
  #right-btn {
    padding: 3px;

    &.left-btn {
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      background: linear-gradient(145deg, #171717, #272627);
    }

    &.right-btn {
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
      background: linear-gradient(145deg, #171717, #272627);
    }
  }

  button:active {
    transform: translate(2px, 3px);
  }

  .funct {
    background-color: #3F51B5;
  }

  .ans {
    background-color: #3F51B5;
  }

  @media screen and (min-width: 1200px) {
    .box-test {
      small {
        display: none;
      }
    }

    .container {
      padding: 20px;
      border-radius: 20px;
    }
  }

  @media screen and (min-width: 768px) {
    .container {
      padding: 20px;
      border-radius: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      padding: 20px;
      border-radius: 20px;
    }
  }
}
