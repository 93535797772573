.list-service {

    h2 {
        text-transform: uppercase;
    }

    .card-top {
        position: relative;

        .card-img-top {
            aspect-ratio: 1 / 1;
            object-fit: cover;
        }
    }

    .card-title {
        text-transform: capitalize;
        position: absolute;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        margin: 0;
        color: #fff;
        padding: 10px;
        width: 100%;
    }

    ul {
        padding: 0;
        list-style: none;
        text-align: left;
    }
}