footer {
    ul {
        margin: 0 0 20px 0;
        padding: 0;
        list-style: none;
        display: flex;
        gap: 15px;
        justify-content: flex-end;
        flex-direction: column;

        .menu-item a {
            color: #fff;
            text-decoration: underline; 
        }

        @media (min-width: 768px) {
            flex-direction: row;
            margin: 0;
        }
    }
}